import React from 'react';
import MDElement from '../MDElement';

const HowToUsePbot = () => {
 
    const style = {textAlign: "left"}
    return (
        <>

            <MDElement path="HowTo/LandingPage.md" />
        </>
    )
};

export default HowToUsePbot;
